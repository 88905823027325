export default [
  {
    path: '/',
    name: 'starter',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/CommingSoon.vue'),
  },
  {
    path: '/comming-soon',
    name: 'commingsoon',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/CommingSoon.vue'),
  },
  {
    path: '/404',
    name: 'error',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/404.vue'),
  },
];
